import "./App.css";
import { useState } from "react";

function App() {
  const [lista, setLista] = useState([
    "Figurin (ž)",
    "Fjoret (ž)",
    "Kolajna (ž)",
    "Kolapjani (m)",
    "Lađarice (ž)",
    "Luka RI (ž)",
    "Mirabela (ž)",
    "Neverin (ž)",
    "Štorija (ž)",
    "Vežilica (mj)",
    "Vinčace (m)",
    "Zvizdan (ž)",
    "Ćakulone (ž)",
    "Elektrodalmacija (mj)",
    "Partenca (m)",
    "Amorin (ž)",
    "Bistica (m)",
  ]);
  const [sredjenaLista, setSredjenaLista] = useState([]);

  function handleDraw() {
    if (lista.length > 0) {
      const random = Math.floor(Math.random() * lista.length);
      setSredjenaLista([...sredjenaLista, lista[random]]);
      setLista(lista.filter((item, index) => index !== random));
    }
  }

  return (
    <div className="App">
      <h1 className="naslov">
        Večeri dalmatinske klapske pisme
        <br />
        Kaštela 2022.
      </h1>
      <h2 className="popis">Popis izvođača:</h2>
      <ul className="neizabrani">
        {lista.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      <button onClick={handleDraw} className="botun">
        Izvuci sljedećeg
      </button>
      <h2 className="redoslijed">Redoslijed nastupa izvođača:</h2>
      <ul className="sređeno">
        {sredjenaLista.map((item, index) => {
          return (
            <li key={index}>
              {index + 1}. {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default App;
